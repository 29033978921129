

input{
      padding:13px 14px;
      width: 100%;
}
input:focus-visible{
      outline: none !important;
}
.input-group-calender {
      padding-left: 15px;
      display:'flex';
    }


    .react-datepicker__month-container {
      background-color: #fff;
      color: #969eac;
      padding: 10px;
      border: none !important;
    }

      .react-datepicker__month {
        padding: 1rem 0 33px !important;
      }

      .react-datepicker__month-text {
        display: inline-block;
        width: 5rem;
        margin: 0.5rem;

        padding: 0.2rem;

      }
    .react-datepicker{
      box-shadow: 0px 8px 24px #0717411F;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 6px;
      border: 'none';
    }
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
      display: none;
    }
    .react-datepicker__header{
      background-color: #fff !important;
      border-bottom:none !important
    }
    .react-datepicker__header__dropdown{
      display: flex;
      justify-content: center;

    }
    .react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll{
      width: 100%;
      padding: 10px;
      background-color: #F5F7FA;
    }
    .react-datepicker__month-select{
      width: 100%;
      background-color: #F5F7FA;
      border: none !important;
      font-family:"IBMPlexSans-SemiBold";
    }
    select.react-datepicker__year-select:focus-visible{
      outline: none !important;
      border: none !important;
    }
    .react-datepicker__month-select:focus-visible{
      outline: none !important;
      border: none !important;
    }
    .react-datepicker__year-select{
      width: 100%;
      background-color: #F5F7FA;
      border: none !important;
      font-family:"IBMPlexSans-SemiBold";
    }
    .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
      display: none !important;
    }
    .react-datepicker__navigation{
      display: none !important;
    }
    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
          border-radius: 50% !important;
          color: #fff !important;
    }
    .react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text{
color:#6A7888
    }
    .react-datepicker{
          border: none !important;
    }
    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
          border-radius: 50%;
          color:#fff !important
    }