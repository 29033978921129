.main-wrapper {
      height: 60vh;
      margin: 10px 50px;
      filter: drop-shadow(-1px 5px 3px #ccc);
  }

  .info-wrapper {
      margin-top: 15px;
  }

  .map-details {
      text-align: center;
      font-size: 1.2em;
  }

  .map-details span {
      font-weight: bold;
  }

  .search-input {
      font-size: 14px;
      width: 100%;
      outline: none;
  }
   .icon-wrapper{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    /* margin-left: 40px; */
    /* padding: 4px; */
}   

