@import url(https://cdn.syncfusion.com/ej2/material.css);
input {
  padding: 13px 14px;
  width: 100%;
}

input:focus-visible {
  outline: none !important;
}

.input-group-calender {
  padding-left: 15px;
  display: 'flex';
}


.react-datepicker__month-container {
  background-color: #fff;
  color: #969eac;
  border: none !important;
   margin: 12px;
   border-radius: 4px;
   height: auto;
}

.react-datepicker__month-container .react-datepicker__month {
  padding: 0  0 4px 0 !important;
}

.react-datepicker__month-text {
  display: inline-block;
  width: 5rem;
  margin: 0.5rem;

  padding: 0.2rem;

}

.react-datepicker {
  box-shadow: 0px 8px 24px #0717411F;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 6px;
  border: 'none';
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  display: none;
}

.react-datepicker__header {
  background-color: #fff !important;
  border-bottom: none !important
}

.react-datepicker__header__dropdown {
  display: flex;
  justify-content: center;
  padding:4px
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  width: 100%;
  padding: 4px !important;
  background-color: #F5F7FA;
  border: 1px solid !important;
  border-radius:4px !important;
}

.react-datepicker__month-select {
  width: 100%;
  background-color: #fff !important;
  border: none !important;
  font-family: "'IBMPlexSans-SemiBold'";
}

select.react-datepicker__year-select:focus-visible {
  outline: none !important;
  border: none !important;
}

.react-datepicker__month-select:focus-visible {
  outline: none !important;
  border: none !important;
}

.react-datepicker__year-select {
  width: 100%;
  background-color: #fff !important;
  border: none !important;
  font-family: "'IBMPlexSans-SemiBold'";
}

.react-datepicker-time__header {
  display: block !important;
}

.react-datepicker__current-month,
.react-datepicker-year-header {
  display: block !important;
}

.react-datepicker__navigation {
  display: block !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50% !important;
  color: #fff !important;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  color: #6A7888
}

.react-datepicker {
  border: none !important;
  background-color: #F5F7FA !important;
  border-radius: 4px;
  box-shadow: 0px 0px 16px #00000014  ;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 50%;
  color: #fff !important
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  background-color: #F5F7FA;
  padding: 10px;
  /* border: none !important; */
}
/* new styles */
.react-datepicker__time-container {
  border-left: none !important;
  margin:12px 12px 12px 0px;
  border-radius: 4px;
}
.react-datepicker__month-read-view{
  visibility: visible !important;
}
.react-datepicker__year-read-view{
  visibility: visible !important;
}
.react-datepicker__year-read-view--down-arrow,.react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  transform: rotate(135deg);
  right: 0px !important;
  top: 0px;
  visibility: visible !important;
}
.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
  line-height: 30px !important;
}
/* .react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
  background-color: white !important;
} */
.react-datepicker__month-dropdown{
  background-color: white !important;
  height:275px !important;
  overflow:auto !important;
  left:0% !important;
  margin-top: 15px !important;
}
.react-datepicker__year-dropdown{
  background-color: white !important;
  height:275px !important;
  overflow:auto !important;
  left:50% !important;
  margin-top: 15px !important;
}
.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll{
  background-color: white!important;
}
input {
  border: none !important;
  border-radius: 4px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  display: flex;
  align-items: center;
  background-color: #5078E1;
  justify-content: center;
}
input {
  padding: 13px 14px;
  width: 100%;
}

input:focus-visible {
  outline: none !important;
}

.input-group-calender {
  padding-left: 15px;
  display: 'flex';
}


.react-datepicker__month-container {
  background-color: #fff;
  color: #969eac;
  padding: 10px;
  border: none !important;
}

.react-datepicker__month {
  padding: 1rem 0 33px !important;
}

.react-datepicker__month-text {
  display: inline-block;
  width: 5rem;
  margin: 0.5rem;

  padding: 0.2rem;

}

.react-datepicker {
  box-shadow: 0px 8px 24px #0717411F;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 6px;
  border: 'none';
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  display: none;
}

.react-datepicker__header {
  background-color: #fff !important;
  border-bottom: none !important
}

.react-datepicker__header__dropdown {
  display: flex;
  justify-content: center;

}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  width: 100%;
  padding: 10px;
  background-color: #F5F7FA;
  margin: 0 5px !important;
}
.react-datepicker__month-select {
  width: 100%;
  background-color: #F5F7FA;
  border: none !important;
  font-family: "IBMPlexSans-SemiBold";
}

select.react-datepicker__year-select:focus-visible {
  outline: none !important;
  border: none !important;
}

.react-datepicker__month-select:focus-visible {
  outline: none !important;
  border: none !important;
}

.react-datepicker__year-select {
  width: 100%;
  background-color: #F5F7FA;
  border: none !important;
  font-family: "IBMPlexSans-SemiBold";
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  display: none !important;
}

.react-datepicker__navigation {
  display: none !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50% !important;
  color: #fff !important;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  color: #6A7888
}

.react-datepicker {
  border: none !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 50%;
  color: #fff !important
}
.main-wrapper {
      height: 60vh;
      margin: 10px 50px;
      filter: drop-shadow(-1px 5px 3px #ccc);
  }

  .info-wrapper {
      margin-top: 15px;
  }

  .map-details {
      text-align: center;
      font-size: 1.2em;
  }

  .map-details span {
      font-weight: bold;
  }

  .search-input {
      font-size: 14px;
      width: 100%;
      outline: none;
  }
   .icon-wrapper{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    /* margin-left: 40px; */
    /* padding: 4px; */
}   


/* WebKit browsers */
.pac-container{
    z-index: 9999;
    box-shadow: 0px 8px 23px #0000001A;
    border-radius: 4px;
    min-width: 400px;
    top:20px;
    margin-top: 25px;
    margin-left: -20px;
}
.pac-item{
    padding:5px;
    cursor: pointer;
}
.pac-container:after{
    background-image: none !important;
    height: 0px;
}

.input_placeholder_color:focus::-webkit-input-placeholder {
    color: #E0E0E0;
  }
  /* Mozilla Firefox 4 to 18 */
  .input_placeholder_color:focus:-moz-placeholder {
    color: #E0E0E0;
  }
  /* Mozilla Firefox 19+ */
  .input_placeholder_color:focus::-moz-placeholder {
    color: #E0E0E0;
  }
  /* Internet Explorer 10+ */
  
  .input_placeholder_color::placeholder{
    color: #6F6F6F;
    font-size: 12px;
  }
.map-wrapper{
    height: 100%;
    width: 100%;
}
#map .gm-style-iw-c{
    /* padding:0 !important; */
    box-shadow: 0px 15px 50px #0000002E !important;
}
#map .gm-style-iw-d{
    overflow: auto !important;
}
#map .gm-ui-hover-effect{
    display: none !important;
}

#map .controls{
    right: 20px !important;
    bottom: 20px !important;
}
#map .fullscreen-control{
    right: 20px !important;
    top: 20px !important;
}

#map .icon-wrapper{
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
}

#map .mb-20{
    margin-bottom: 20px;
}
#map .gmnoprint{
    display: none !important;
}
#map a[href^="http://maps.google.com/maps"],
a[href^="https://maps.google.com/maps"],
a[href^="https://www.google.com/maps"] {
    display: none !important;
}

.is-fullscreen{
height:100vh !important;
}

/* .is-fullscreen #map{
height:calc(100vh - 67px) !important;
} */

.is-fullscreen .fullscreen-control{
    display: none;
}

.is-fullscreen .flexRow{
    display: flex;
    align-items: center;   
    padding:20px;
    background-color: #F1F3F7; 
}
.flexRow{
    display: none;  
}

#map .map-types{
    left: 20px !important;
    bottom: 20px !important;
}

#map .map-types .mapTypeflex{
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

#map .map-types .mapTypeCard{
    width:54px;
    height: 54px;
    border-radius: 6px;
    position: relative;
    cursor: pointer;
}

#map .map-types .mapTypeCard img{
    max-width: 100%;
    height: auto;
    object-fit: cover;
}

#map .map-types .absoluteDiv{
    position: absolute;
    inset: auto 0 0 0;
    background-color: #000000;
    opacity: 0.5;
    border-radius: 0px 0px 6px 6px;
    text-align: center;
    color: #FFFFFF;
    font-family:"crayond_bold";
    font-size: 8px;
}
.radius{
    display: flex;
    align-items: center;
        margin-top: 12px;
    border-radius: 4px;
    overflow: hidden;
}
.km{
padding: 12px;
background-color: white;
border-right: 1px solid #E4E8EE;
cursor: pointer;
}
.km-active{
 padding: 12px;
background-color: #5078E1;
color: white;
cursor: pointer;
}
.zoom-control{
    float: right;
    border-radius: 4px;
    overflow: hidden;
      margin-top: 12px;
    border: 1px solid #E4E8EE;
}
.zoom-control-rtl {
    float: left;
    border-radius: 4px;
    overflow: hidden;
      margin-top: 12px;
    border: 1px solid #E4E8EE;
}
.plus{
background-color: white;
padding: 12px 12px 8px 12px;
border-bottom: 1px solid #E4E8EE;
cursor: pointer;
}
.minus{
background-color: white;
padding: 8px 12px 12px 12px;
cursor: pointer;
}
.map-controls{
    margin-right: 12px;
}
.marker-label{
    color: white !important;
    margin-top: -16px;
    font-family: "'NunitoSans-Bold'" !important;
}
.marker-label2{
    display: none;
   
}
.plus{
    background-color: white;
    padding: 5px 12px 8px 12px;
    border-bottom: 1px solid #E4E8EE;
    cursor: pointer;
    }
    .minus{
    background-color: white;
    padding: 0px 12px 9px 12px;
    cursor: pointer;

    }


input{
      padding:13px 14px;
      width: 100%;
}
input:focus-visible{
      outline: none !important;
}
.input-group-calender {
      padding-left: 15px;
      display:'flex';
    }


    .react-datepicker__month-container {
      background-color: #fff;
      color: #969eac;
      padding: 10px;
      border: none !important;
    }

      .react-datepicker__month {
        padding: 1rem 0 33px !important;
      }

      .react-datepicker__month-text {
        display: inline-block;
        width: 5rem;
        margin: 0.5rem;

        padding: 0.2rem;

      }
    .react-datepicker{
      box-shadow: 0px 8px 24px #0717411F;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 6px;
      border: 'none';
    }
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
      display: none;
    }
    .react-datepicker__header{
      background-color: #fff !important;
      border-bottom:none !important
    }
    .react-datepicker__header__dropdown{
      display: flex;
      justify-content: center;

    }
    .react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll{
      width: 100%;
      padding: 10px;
      background-color: #F5F7FA;
    }
    .react-datepicker__month-select{
      width: 100%;
      background-color: #F5F7FA;
      border: none !important;
      font-family:"IBMPlexSans-SemiBold";
    }
    select.react-datepicker__year-select:focus-visible{
      outline: none !important;
      border: none !important;
    }
    .react-datepicker__month-select:focus-visible{
      outline: none !important;
      border: none !important;
    }
    .react-datepicker__year-select{
      width: 100%;
      background-color: #F5F7FA;
      border: none !important;
      font-family:"IBMPlexSans-SemiBold";
    }
    .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
      display: none !important;
    }
    .react-datepicker__navigation{
      display: none !important;
    }
    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
          border-radius: 50% !important;
          color: #fff !important;
    }
    .react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text{
color:#6A7888
    }
    .react-datepicker{
          border: none !important;
    }
    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
          border-radius: 50%;
          color:#fff !important
    }


input{
      padding:13px 14px;
      width: 100%;
}
input:focus-visible{
      outline: none !important;
}
.input-group-calender {
      padding-left: 15px;
      display:'flex';
    }


    .react-datepicker__month-container {
      background-color: #fff;
      color: #969eac;
      padding: 10px;
      border: none !important;
    }

      .react-datepicker__month {
        padding: 1rem 0;
      }

      .react-datepicker__month-text {
        display: inline-block;
        width: 5rem;
        margin: 0.5rem;

        padding: 0.2rem;

      }
    .react-datepicker{
      box-shadow: 0px 8px 24px #0717411F;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 6px;
      border: 'none';
    }
    .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
      display: none;
    }
    .react-datepicker__header{
      background-color: #fff !important;
      border-bottom:none !important
    }
    .react-datepicker__header__dropdown{
      display: flex;
      justify-content: center;

    }
    .react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll{
      width: 100%;
      padding: 10px;
      background-color: #F5F7FA;
    }
    .react-datepicker__month-select{
      width: 100%;
      background-color: #F5F7FA;
      border: none !important;
      font-family:"IBMPlexSans-SemiBold";
    }
    select.react-datepicker__year-select:focus-visible{
      outline: none !important;
      border: none !important;
    }
    .react-datepicker__month-select:focus-visible{
      outline: none !important;
      border: none !important;
    }
    .react-datepicker__year-select{
      width: 100%;
      background-color: #F5F7FA;
      border: none !important;
      font-family:"IBMPlexSans-SemiBold";
    }
    .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
      display: none !important;
    }
    .react-datepicker__navigation{
      display: none !important;
    }
    .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
          border-radius: 50% !important;
          color: #fff !important;
    }
    .react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text{
color:#6A7888
    }
    .react-datepicker{
          border: none !important;
    }
    .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
          border-radius: 50%;
          color:#fff !important
    }
    .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input{
background-color: #F5F7FA;
padding: 10px;
border: none !important;
    }

.main-wrapper {
    height: 60vh;
    margin: 10px 50px;
    filter: drop-shadow(-1px 5px 3px #ccc);
}

.info-wrapper {
    margin-top: 15px;
}

.map-details {
    text-align: center;
    font-size: 1.2em;
}

.map-details span {
    font-weight: bold;
}

button.rec-dot{
  background-color: white;
  margin-top: -40px;
  z-index: 1;
  box-shadow: none;
  display: none;
}

button.rec-dot:hover, button.rec-dot:active, button.rec-dot:focus  {
  box-shadow: 0 0 1px 3px rgb(177, 173, 173);
}
div.rec-slider-container{
  margin:0px;
}


.MuiPickersLayout-actionBar button:hover{
 background-color: #5078E1 !important; 
 color: #fff !important; 
}
.MuiTreeItem-content{
      height: 40px;
 }

/* chrome and chromium based */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  .react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
 ::-webkit-scrollbar {
   width: 4px !important;
   height: 6px !important;
 }

 /* this targets the default scrollbar (compulsory) */

 /* Track */
 ::-webkit-scrollbar-track {
   background: transparent !important;
 }

 /* Hide Number Input Roller */
 input[type=number]::-webkit-inner-spin-button,
 input[type=number]::-webkit-outer-spin-button {
   -webkit-appearance: none;
   margin: 0;
 }

 /* Handle */
 ::-webkit-scrollbar-thumb {
   background: rgba(0, 0, 0, 0.23) !important;
   border-radius: 24px;
   -webkit-transition: all 0.5s;
   transition: all 0.5s;
   cursor: pointer;
 }

 /* Handle on hover */
 ::-webkit-scrollbar-thumb:hover {
   background: rgba(146, 151, 165, 1) !important;
 }

 /* this will style the thumb, ignoring the track */

 ::-webkit-scrollbar-button {
   background-color: transparent !important;
 }

 /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

 ::-webkit-scrollbar-corner {
   background-color: transparent !important;
 }


 iframe::-webkit-scrollbar-thumb {
   background: rgba(0, 0, 0, 0.23) !important;
   border-radius: 24px;
   -webkit-transition: all 0.5s;
   transition: all 0.5s;
   cursor: pointer;
 }

 /* Handle on hover */
 #viewer #container #main #scroller::-webkit-scrollbar-thumb:hover {
   background: rgba(146, 151, 165, 1) !important;
 }

 /* this will style the thumb, ignoring the track */

 iframe::-webkit-scrollbar-button {
   background-color: transparent !important;
 }

 /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

 iframe::-webkit-scrollbar-corner {
   background-color: transparent !important;
 }

 /* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */
 @font-face {
  font-family: "IBMPlexSans-SemiBold";
  src: url(/static/media/IBMPlexSans-SemiBold.39ec72d9.ttf);
}

@font-face {
  font-family: "IBMPlexSans-Regular";
  src: url(/static/media/IBMPlexSans-Regular.5892d3ae.ttf);
}

@font-face {
  font-family: "IBMPlexSans-ExtraBold";
  src: url(/static/media/IBMPlexSans-Bold.fc1f7120.ttf);
}

@font-face {
  font-family: "IBMPlexSans-Bold";
  src: url(/static/media/IBMPlexSans-Bold.fc1f7120.ttf);
}

@font-face {
  font-family: "IBMPlexSans-Italic";
  src: url(/static/media/IBMPlexSans-Italic.d8c264ef.ttf);
}

/* ARABIC FONT FAMILY */

@font-face {
  font-family: "IBMPlexSansArabic-SemiBold";
  src: url(/static/media/IBMPlexSansArabic-SemiBold.6a4c85b4.ttf);
}

@font-face {
  font-family: "IBMPlexSansArabic-Regular";
  src: url(/static/media/IBMPlexSansArabic-Regular.dae364fb.ttf);
}

@font-face {
  font-family: "IBMPlexSansArabic-ExtraBold";
  src: url(/static/media/IBMPlexSansArabic-Bold.4414bb5d.ttf);
}

@font-face {
  font-family: "IBMPlexSansArabic-Bold";
  src: url(/static/media/IBMPlexSansArabic-Bold.4414bb5d.ttf);
}

@font-face {
  font-family: "IBMPlexSansArabic-Italic";
  src: url(/static/media/IBMPlexSansArabic-Light.4594b40b.ttf);
}

 /* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_9785">Text with this font applied</p> */
 html {
   height: 100%;
 }

 #root {
   height: 100%;
 }

 /* input-range-slider */
 /* input-range-track */
 /* _input-range-slider.scss */
 .input-range__slider {
   border-radius: 100%;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: center;
   height: 30px;
   height: 30px;
   margin-left: -1rem;
   margin-top: -18px;
   outline: none;
   position: absolute;
   top: -1px;
   transition: left 200ms ease-out;
   will-change: left;
 }

 .input-range__slider::before {
   background: #fff;
   border-radius: 100%;
   display: block;
   content: '';
   height: 20px;
   width: 20px;
   transition: transform 200ms ease-out, box-shadow 200ms ease-out;
   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
   will-change: transform, box-shadow;
   border: 5px solid #5078E1;
 }

 .input-range__slider:active::before {
   transform: scale(1.15);
 }

 .input-range--disabled .input-range__slider::before {
   background: #ccc;
   border: 1px solid #ccc;
   box-shadow: none;
   transform: none;
 }

 /* _input-range-label.scss */
 /* not imported */
 /* _input-range-label-container.scss */
 .input-range__label-container {
   display: none;
 }

 .input-range__label-container .input-range__label--min,
 .input-range__label-container .input-range__label--max {
   display: none;
 }

 /* _input-range-track.scss */
 .input-range__track {
   background: #e6e9ea;
   border-radius: 3px;
   cursor: pointer;
   display: block;
   height: 10px;
   position: relative;
   transition: left 200ms ease-out, width 200ms ease-out;
   will-change: left, width;
 }

 .input-range--disabled .input-range__track {
   background: #e6e9ea;
 }

 .input-range__track--background {
   margin-top: -1.5px;
   top: 50%;
   position: absolute;
   left: 0;
   right: 0;
 }

 .input-range__track--active {
   background: #5078E1;
 }

 /* input-range */
 .input-range {
   height: 1rem;
   position: relative;
   width: 100%;
 }

 .options {
   padding: 2px;
 }

 .MuiTimelineItem-root:before {
   flex: 0 1 !important;
 }

 /* .recharts-sector {
  stroke-linejoin: round;
  stroke-width: 7px;
} */

 .react-datepicker__month-read-view--down-arrow {
   right: 0px;
   margin-top: 4px;
 }

 .datepicker__month-dropdown-container--scroll {
   display: flex;
   align-items: center;
 }

 .react-datepicker__month-read-view {
   display: flex;
   align-items: center;
 }

 .react-datepicker__month-option {
   background-color: #fff;
   padding: 4px;
 }

 .css-1eppkgy-MuiTypography-root-MuiTimelineContent-root{
  padding: 6px 6px 24px 12px;
 }

 .react-tel-input .country-list .search{
  display: flex !important;
 }

 .react-tel-input .country-list{
  box-shadow: none !important;
  border: 1px solid #E4E8EE;
  border-radius: 4px;
  font-family: IBMPlexSans-SemiBold !important;

 }

 .react-tel-input .form-control{
  font-family: IBMPlexSans-SemiBold !important;

 }

 .react-tel-input .country-list .search-box{
  font-family: IBMPlexSans-SemiBold !important;
 }

 .react-tel-input .form-control{
  width: 100% !important;
  height: 45px;
 }

 .react-tel-input .flag-dropdown{
  background-color: none;
  border: none;
 }

 .react-tel-input .selected-flag{
  width: 40px !important;
 }

 .dropdown-top {
  position: absolute;
  top: -100%; /* Adjust this value to position the dropdown correctly */
  transform: translateY(-100%); /* Adjust this value to position the dropdown correctly */
}

.ql-toolbar.ql-snow .ql-formats{
  margin-right: 8px !important;
}
.image {
  background-color: red !important
}

.MuiTab-root {
  padding: 0px !important
}

.react-datepicker-wrapper{
  width: 100%;
}
html[dir="rtl"] {
  font-size: 110% !important;
}
html[dir="ltr"] {
  font-size: 100% !important;
}
.MuiTypography-root {
  letter-spacing: 0;
}
