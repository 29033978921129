input {
  padding: 13px 14px;
  width: 100%;
}

input:focus-visible {
  outline: none !important;
}

.input-group-calender {
  padding-left: 15px;
  display: 'flex';
}


.react-datepicker__month-container {
  background-color: #fff;
  color: #969eac;
  border: none !important;
   margin: 12px;
   border-radius: 4px;
   height: auto;
}

.react-datepicker__month-container .react-datepicker__month {
  padding: 0  0 4px 0 !important;
}

.react-datepicker__month-text {
  display: inline-block;
  width: 5rem;
  margin: 0.5rem;

  padding: 0.2rem;

}

.react-datepicker {
  box-shadow: 0px 8px 24px #0717411F;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 6px;
  border: 'none';
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  display: none;
}

.react-datepicker__header {
  background-color: #fff !important;
  border-bottom: none !important
}

.react-datepicker__header__dropdown {
  display: flex;
  justify-content: center;
  padding:4px
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  width: 100%;
  padding: 4px !important;
  background-color: #F5F7FA;
  border: 1px solid !important;
  border-radius:4px !important;
}

.react-datepicker__month-select {
  width: 100%;
  background-color: #fff !important;
  border: none !important;
  font-family: "'IBMPlexSans-SemiBold'";
}

select.react-datepicker__year-select:focus-visible {
  outline: none !important;
  border: none !important;
}

.react-datepicker__month-select:focus-visible {
  outline: none !important;
  border: none !important;
}

.react-datepicker__year-select {
  width: 100%;
  background-color: #fff !important;
  border: none !important;
  font-family: "'IBMPlexSans-SemiBold'";
}

.react-datepicker-time__header {
  display: block !important;
}

.react-datepicker__current-month,
.react-datepicker-year-header {
  display: block !important;
}

.react-datepicker__navigation {
  display: block !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50% !important;
  color: #fff !important;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  color: #6A7888
}

.react-datepicker {
  border: none !important;
  background-color: #F5F7FA !important;
  border-radius: 4px;
  box-shadow: 0px 0px 16px #00000014  ;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 50%;
  color: #fff !important
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  background-color: #F5F7FA;
  padding: 10px;
  /* border: none !important; */
}
/* new styles */
.react-datepicker__time-container {
  border-left: none !important;
  margin:12px 12px 12px 0px;
  border-radius: 4px;
}
.react-datepicker__month-read-view{
  visibility: visible !important;
}
.react-datepicker__year-read-view{
  visibility: visible !important;
}
.react-datepicker__year-read-view--down-arrow,.react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  transform: rotate(135deg);
  right: 0px !important;
  top: 0px;
  visibility: visible !important;
}
.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
  line-height: 30px !important;
}
/* .react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
  background-color: white !important;
} */
.react-datepicker__month-dropdown{
  background-color: white !important;
  height:275px !important;
  overflow:auto !important;
  left:0% !important;
  margin-top: 15px !important;
}
.react-datepicker__year-dropdown{
  background-color: white !important;
  height:275px !important;
  overflow:auto !important;
  left:50% !important;
  margin-top: 15px !important;
}
.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll{
  background-color: white!important;
}
input {
  border: none !important;
  border-radius: 4px !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  display: flex;
  align-items: center;
  background-color: #5078E1;
  justify-content: center;
}