
@import url("https://cdn.syncfusion.com/ej2/material.css");
.main-wrapper {
    height: 60vh;
    margin: 10px 50px;
    filter: drop-shadow(-1px 5px 3px #ccc);
}

.info-wrapper {
    margin-top: 15px;
}

.map-details {
    text-align: center;
    font-size: 1.2em;
}

.map-details span {
    font-weight: bold;
}

button.rec-dot{
  background-color: white;
  margin-top: -40px;
  z-index: 1;
  box-shadow: none;
  display: none;
}

button.rec-dot:hover, button.rec-dot:active, button.rec-dot:focus  {
  box-shadow: 0 0 1px 3px rgb(177, 173, 173);
}
div.rec-slider-container{
  margin:0px;
}


.MuiPickersLayout-actionBar button:hover{
 background-color: #5078E1 !important; 
 color: #fff !important; 
}